<template>
  <v-dialog
    v-model="dialog"
    width="auto"
    max-width="1400"
    scrollable
  >
    <template #activator="{ on }">
      <v-btn
        v-if="element == 'btn'"
        color="success"
        fab
        small
        class="mt-n5"
        v-on="on"
      >
        <v-icon small>
          fal fa-plus
        </v-icon>
      </v-btn>
      <v-list-item
        v-if="element == 'list-item'"
        selectable
        v-on="on"
      >
        <v-list-item-action class="mr-4">
          <v-icon small>
            fal fa-edit fa-fw
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Edit Formulary</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="formulary.name"
          label="Formulary Name"
          outlined
          dense
        />
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row
          class="no-gutters"
        >
          <v-col
            v-for="drug in drugs"
            :key="drug.id"
            cols="3"
          >
            <v-checkbox
              v-model="formulary_drugs"
              :value="drug.id"
              :label="drug.short_name"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        {{ formulary_drugs.length }} drugs selected
        <v-spacer />
        <v-btn
          text
          @click="dialog = false"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="drugsLoading"
          @click="updateFormulary"
        >
          {{ $t('Update') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    formulary: {
      type: Object,
      default: () => ({
        name: '',
      }),
    },
    element: {
      type: String,
      default: 'list-item',
    },
  },
  data: () => ({
    dialog: false,
    loading: false,
    drugsLoading: false,
    drugs: [],
    formulary_drugs: [],
  }),
  watch: {
    dialog () {
      if (!this.drugs.length) {
        this.fetchDrugs()
      }
      if (this.formulary.id) {
        this.fetchFormulary()
      }
    },
  },
  methods: {
    fetchDrugs () {
      this.loading = true
      const promise = this.axios.get('admin/drugs', {
        params: {
          quick: true,
          count: 10000,
        },
      })
      return promise.then((response) => {
        this.drugs = response.data.data
        if (!this.formulary.id) {
          this.formulary_drugs = this.drugs.map(drug => drug.id)
        }
      }).catch(error => {
        this.$toast.error(error)
      }).finally(() => {
        this.loading = false
      })
    },
    fetchFormulary () {
      this.loading = true
      const promise = this.axios.get('admin/drugs/formularies/' + this.formulary.id)
      return promise.then((response) => {
        this.formulary_drugs = response.data.drugs.map(drug => drug.id)
      }).catch(error => {
        this.$toast.error(error)
      }).finally(() => {
        this.loading = false
      })
    },
    updateFormulary () {
      this.loading = true

      this.axios.request({
        method: this.formulary.id ? 'patch' : 'post',
        url: `admin/drugs/formularies/${this.formulary.id || ''}`,
        data: {
          ...this.formulary,
          ...{
            drugs: this.formulary_drugs,
          },
        },
      })
        .then(() => {
          this.$toast.success('Formulary updated successfully')
          this.$emit('update')
          this.dialog = false
        })
        .catch(error => {
          this.$toast.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
